import React, { useEffect, useState } from "react";
import { Link } from "lucide-react";
import { IntegrationsList } from "./IntegrationsList";
import { IntegrationModal } from "./IntegrationModal";
import { CompanyFilter } from "../CompanyFilter";
import { IntegrationFeedback, UserProgress } from "../../types/nocodb";
import {
  getUserProgress,
  getIntegrationFeedback,
} from "../../services/nocodbApi";

export const IntegrationsPage: React.FC = () => {
  const [integrations, setIntegrations] = useState<{
    [key: string]: IntegrationFeedback[];
  }>({});
  const [users, setUsers] = useState<{ [key: string]: UserProgress }>({});
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [feedback, userData] = await Promise.all([
          getIntegrationFeedback(),
          getUserProgress(),
        ]);

        // Create users lookup map
        const usersMap = userData.reduce(
          (acc: { [key: string]: UserProgress }, user) => {
            acc[user.frontegg_email] = user;
            return acc;
          },
          {},
        );

        // Group feedback by client email
        const groupedFeedback = feedback.reduce(
          (acc: { [key: string]: IntegrationFeedback[] }, item) => {
            const email = item.Client;
            if (!acc[email]) {
              acc[email] = [];
            }
            acc[email].push(item);
            return acc;
          },
          {},
        );

        setUsers(usersMap);
        setIntegrations(groupedFeedback);
      } catch (err) {
        setError("Failed to load integrations");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Get unique companies from user data
  const companies = [
    ...new Set(
      Object.keys(integrations).map(
        (email) => users[email]?.frontegg_accountname || "Unknown Company",
      ),
    ),
  ].sort();

  // Filter integrations based on selected company
  const filteredIntegrations = selectedCompany
    ? Object.entries(integrations).reduce(
        (acc: { [key: string]: IntegrationFeedback[] }, [email, feedbacks]) => {
          if (users[email]?.frontegg_accountname === selectedCompany) {
            acc[email] = feedbacks;
          }
          return acc;
        },
        {},
      )
    : integrations;

  return (
    <div className="flex-1 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">
            Integration Requests
          </h1>
          <p className="text-gray-500">
            View and manage integration requests from users
          </p>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
          </div>
        ) : error ? (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg">{error}</div>
        ) : (
          <>
            <CompanyFilter
              companies={companies}
              selectedCompany={selectedCompany}
              onSelectCompany={setSelectedCompany}
              totalUsers={Object.keys(filteredIntegrations).length}
            />
            <IntegrationsList
              integrations={filteredIntegrations}
              users={users}
              onSelectEmail={setSelectedEmail}
            />
          </>
        )}

        {selectedEmail && (
          <IntegrationModal
            email={selectedEmail}
            user={users[selectedEmail]}
            integrations={integrations[selectedEmail]}
            onClose={() => setSelectedEmail(null)}
          />
        )}
      </div>
    </div>
  );
};
