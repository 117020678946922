import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '@frontegg/react';
import { Login } from './components/Login';
import { OAuthCallback } from './components/OAuthCallback';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Navigation } from './components/Navigation';
import { Dashboard } from './components/Dashboard';
import { IntegrationsPage } from './components/IntegrationsPage/IntegrationsPage';
import { SolutionsPage } from './components/SolutionsPage/SolutionsPage';
import { DesktopPage } from './components/DesktopPage/DesktopPage';
import { FinancialPage } from './components/FinancialPage/FinancialPage';
import { FeedbackPage } from './components/FeedbackPage/FeedbackPage';
import { ApplicationsPage } from './components/ApplicationsPage/ApplicationsPage'; // Added import


export function App() {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <Routes>
      <Route 
        path="/login" 
        element={
          isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />
        } 
      />
      <Route path="/oauth/callback" element={<OAuthCallback />} />
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="dashboard" />
              <Dashboard />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/applications"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="applications" />
              <ApplicationsPage />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/solutions"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="solutions" />
              <SolutionsPage />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/applications"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="applications" /> {/* Added Navigation for Applications */}
              <ApplicationsPage />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/desktop"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="desktop" />
              <DesktopPage />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/feedback"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="feedback" />
              <FeedbackPage />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/integrations"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="integrations" />
              <IntegrationsPage />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/financial"
        element={
          <ProtectedRoute>
            <div className="flex min-h-screen bg-gray-100">
              <Navigation currentRoute="financial" />
              <FinancialPage />
            </div>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}