import React from 'react';
import { Building2, Users, BarChart3 } from 'lucide-react';
import { UserProgress } from '../types/nocodb';
import { useState, useEffect } from 'react';
import { getUserProgress } from '../services/nocodbApi';

interface CompanyMetrics {
  name: string;
  userCount: number;
  activeUsers: number;
  engagementRate: number;
  solutionsProgress: number;
  desktopProgress: number;
}

export function TopCompanies() {
  const [companies, setCompanies] = useState<CompanyMetrics[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUsers = await getUserProgress();
        const companyMap = new Map<string, UserProgress[]>();

        // Group users by company, filtering out invalid entries
        allUsers.forEach(user => {
          if (user && user.frontegg_accountname) { //Added check for valid user object and company name
            if (!companyMap.has(user.frontegg_accountname)) {
              companyMap.set(user.frontegg_accountname, []);
            }
            companyMap.get(user.frontegg_accountname)?.push(user);
          }
        });

        // Calculate metrics for each company
        const companyMetrics: CompanyMetrics[] = Array.from(companyMap.entries()).map(([name, users]) => {
          // Count active users (those with any progress)
          const activeUsers = users.filter(user =>
            (user.solutions_progress > 0 || user.desktop_progress > 0)
          ).length;

          // Calculate engagement rate
          const engagementRate = (activeUsers / users.length) * 100;

          // Calculate average progress
          const solutionsProgress = users.reduce((sum, user) => sum + (user.solutions_progress || 0), 0) / users.length;
          const desktopProgress = users.reduce((sum, user) => sum + (user.desktop_progress || 0), 0) / users.length;

          return {
            name,
            userCount: users.length,
            activeUsers,
            engagementRate,
            solutionsProgress,
            desktopProgress
          };
        });

        // Sort by engagement rate and take top 5
        const topCompanies = companyMetrics
          .sort((a, b) => b.engagementRate - a.engagementRate)
          .slice(0, 5);

        setCompanies(topCompanies);
      } catch (error) {
        console.error('Error fetching top companies:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return (
      <div className="bg-white rounded-xl p-6 shadow-sm h-[300px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl p-6 shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <Building2 className="w-5 h-5 text-blue-500" />
          <h2 className="text-lg font-semibold">Top Companies</h2>
        </div>
      </div>

      <div className="space-y-4">
        {companies.map((company, index) => (
          <div key={company.name} className="flex items-center space-x-4">
            <div className="flex-shrink-0 w-8 h-8 bg-blue-50 rounded-full flex items-center justify-center">
              <span className="text-blue-600 font-semibold">{index + 1}</span>
            </div>

            <div className="flex-grow">
              <div className="flex items-center justify-between">
                <div>
                  <span className="font-medium text-gray-900">{company.name}</span>
                  <div className="flex items-center space-x-4 text-sm text-gray-500">
                    <div className="flex items-center">
                      <Users className="w-4 h-4 mr-1" />
                      <span>{company.userCount} total</span>
                    </div>
                    <div className="flex items-center">
                      <BarChart3 className="w-4 h-4 mr-1" />
                      <span>{company.activeUsers} active</span>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-lg font-semibold text-blue-600">
                    {Math.round(company.engagementRate)}%
                  </div>
                  <div className="text-sm text-gray-500">engagement</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}