import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LayoutDashboard,
  Lightbulb,
  Monitor,
  MessageSquare,
  Link,
  DollarSign,
  LogOut,
  Users,
} from 'lucide-react';
import { useAuth, ContextHolder } from '@frontegg/react';

type Route = 'dashboard' | 'applications' | 'solutions' | 'desktop' | 'feedback' | 'integrations' | 'financial';

interface NavigationProps {
  currentRoute: Route;
}

const navItems = [
  { icon: LayoutDashboard, label: 'Dashboard', route: 'dashboard' as Route },
  { icon: Users, label: 'Applications', route: 'applications' as Route },
  { icon: Lightbulb, label: 'Solutions Progress', route: 'solutions' as Route },
  { icon: Monitor, label: 'Desktop Progress', route: 'desktop' as Route },
  { icon: MessageSquare, label: 'Feedback', route: 'feedback' as Route },
  { icon: Link, label: 'Integrations', route: 'integrations' as Route },
  { icon: DollarSign, label: 'Financial', route: 'financial' as Route },
];

export function Navigation({ currentRoute }: NavigationProps) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleLogout = () => {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`;
  };

  const handleRouteChange = (route: Route) => {
    navigate(`/${route}`);
  };

  return (
    <nav className="bg-gray-900 text-white w-64 min-h-screen p-4">
      <div className="mb-8">
        <h1 className="text-xl font-bold">FixFinder Admin</h1>
        <p className="text-gray-400 text-sm">Research Oversight Portal</p>
        {user && (
          <p className="text-gray-400 text-sm mt-2">
            {user.name}
          </p>
        )}
      </div>
      
      <div className="space-y-2">
        {navItems.map((item) => (
          <button
            key={item.route}
            onClick={() => handleRouteChange(item.route)}
            className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors ${
              currentRoute === item.route
                ? 'bg-gray-800 text-white'
                : 'hover:bg-gray-800 text-gray-300'
            }`}
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </button>
        ))}

        <button
          onClick={handleLogout}
          className="w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors hover:bg-gray-800 text-red-400 hover:text-red-300 mt-8"
        >
          <LogOut className="w-5 h-5" />
          <span>Logout</span>
        </button>
      </div>
    </nav>
  );
}