import React, { useEffect, useState } from "react";
import { Users } from "lucide-react";
import { SolutionsList } from "./SolutionsList";
import { SolutionModal } from "./SolutionModal";
import { CompanyFilter } from "../CompanyFilter";
import { UserProgress } from "../../types/nocodb";
import { getUserProgress } from "../../services/nocodbApi";
import { analyzeSectionCompletion } from "../../utils/progressAnalyzer";

export const SolutionsPage: React.FC = () => {
  const [users, setUsers] = useState<UserProgress[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserProgress | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserProgress = async () => {
      try {
        const userData = await getUserProgress();
        const analyzedData = userData.map(analyzeSectionCompletion);
        setUsers(analyzedData);
      } catch (err) {
        setError("Failed to load user progress");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProgress();
  }, []);

  const companies = [
    ...new Set(users.map((user) => user.frontegg_accountname)),
  ].sort();
  const filteredUsers = selectedCompany
    ? users.filter((user) => user.frontegg_accountname === selectedCompany)
    : users;

  return (
    <div className="flex-1 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">
            Solutions Progress
          </h1>
          <p className="text-gray-500">
            Track user progress through solution sections
          </p>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
          </div>
        ) : error ? (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg">{error}</div>
        ) : (
          <>
            <CompanyFilter
              companies={companies}
              selectedCompany={selectedCompany}
              onSelectCompany={setSelectedCompany}
              totalUsers={filteredUsers.length}
            />
            <SolutionsList
              users={filteredUsers}
              onSelectUser={setSelectedUser}
            />
          </>
        )}

        {selectedUser && (
          <SolutionModal
            user={selectedUser}
            onClose={() => setSelectedUser(null)}
          />
        )}
      </div>
    </div>
  );
};
